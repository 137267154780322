export const apiConfig = {
    powerPointEndPoint: 'https://idpowertoys-release.azurewebsites.net'
}

export const msalConfig = {
    clientId: '904e4864-f3c3-4d2f-ace2-c37a4ed55145'
}

export const envConfig = {
    envName: '[Release]'
}
